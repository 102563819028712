import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {useTranslation} from "react-i18next";
import { NavDropdown } from "react-bootstrap";
import { useNavigate }  from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const toProperty = (e) => {
        e.preventDefault()
        var id = document.getElementById("jumpField").value;
        id = id.replace(/\D+/g, '');
        id = id-1000;
        navigate("/property/" + id, { replace: true })
        navigate(0)
    }
    const toPropertyByReturn = (e) => {
        if (e.key === "Enter") {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            document.getElementById("open_property").click();
        }
    }

    const currentLanguageText = () => {
        if (i18n.language === 'en') {
            return <span className="fi fi-gb"/>
        } else {
            return <span className={'fi fi-'+i18n.language}/>
        }
    }

    return (
        <Navbar className="gradient m-0 fixed-top shadow" expand="md">
            <Navbar.Brand href="/">
                <img
                    src="/MH_logo_outline.svg"
                    width="auto"
                    height="80"
                    className="d-inline-block align-top"
                    alt=""/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav>
                    <Nav.Link className="text-white m-md-3" href="/">{t('start_page')}</Nav.Link>
                    <Nav.Link className="text-white m-md-3" href="/rentable-properties">{t('rentable properties')}</Nav.Link>
                    <Nav.Link className="text-white m-md-3" href="/about">{t('about')}</Nav.Link>
                    <Nav.Link className="text-white m-md-3" href="/contact">{t('contact')}</Nav.Link>
                    <Nav.Link className="text-white m-md-3" href="/reviews">{t('reviews')}</Nav.Link>
                    <Nav.Link className="text-white m-md-3" href="https://minden3d.com/panoramak/360ingatlan/magichouse/iroda/tour.html" target="_blank">{t('check_on_us')}</Nav.Link>
                    <form className="form-inline my-2 my-lg-0">
                        <InputGroup className="mb-3">
                            <Form.Control
                                id="jumpField"
                                placeholder="MH1000"
                                aria-label={t('code')}
                                aria-describedby="open_property"
                                onKeyPress={toPropertyByReturn}
                            />
                            <Button variant="outline-secondary" id="open_property" onClick={toProperty}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} onClick={toProperty}/>
                            </Button>
                        </InputGroup>
                            {/*<input id="jumpField" className="form-control mr-sm-2" type="search" placeholder="MH1000" onKeyPress={toPropertyByReturn} aria-label={t('code')} />*/}
                            {/*<button id="open_property" className="btn btn-outline-success my-2 my-sm-0" onClick={toProperty} type="button">{t('code')}</button>*/}
                    </form>
                    <NavDropdown className="text-white m-md-3" title={currentLanguageText()}>
                        <NavDropdown.Item onClick={() => changeLanguage('hu')}><span className="fi fi-hu"/>Magyar</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => changeLanguage('de')}><span className="fi fi-de"/>Deutch</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => changeLanguage('en')}><span className="fi fi-gb"/>English</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => changeLanguage('es')}><span className="fi fi-es"/>Español</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation