import Form from "react-bootstrap/Form";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {BootstrapContext} from "../context/BootstrapContext";
import {Multiselect} from "multiselect-react-dropdown";
import {useTranslation} from "react-i18next";

const PropertyCitySelect = (props) => {
    const { t } = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);
    const isMultiline = !props.isSingleSelect;
    const [selected, setSelected] = useState(null);
    const onMultiSelectChange = (selectedList, __) => {
        setSelected(selectedList);
        props.onChange(selectedList.map(item => item.id).join(","));
    }
    const onSingleSelectChange = (e) => {
        props.onChange(e.target.value);
    }


    useEffect(() => {
        if (props.defaultValue === undefined || props.defaultValue === null)
            return;
        if (isMultiline) {
            if (selected === null) {
                let sel = props.defaultValue.split(",").map(i => parseInt(i));
                let newSelect = getBootstrapData('city').filter(city => sel.includes(city.id));
                setSelected(newSelect);
            }
        }

    }, [isMultiline, selected, props.defaultValue, getBootstrapData]);


    return (
        <Form.Group controlId={props.controlId}>
            <Form.Label>{t('city')}</Form.Label>
            {isMultiline &&
            <Multiselect
                classNane="bg-secondary"
                options={getBootstrapData('city')} // Options to display in the dropdown
                selectedValues={selected} // Preselected value to persist in dropdown
                onSelect={onMultiSelectChange} // Function will trigger on select event
                onRemove={onMultiSelectChange} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
            />}
            {!isMultiline &&
            <Form.Control as="select"
                          onChange={onSingleSelectChange}
                          defaultValue={parseInt(props.defaultValue)}>
                {!props.defaultValue && <option key="0" value="">{t(props.emptyText)}</option>}
                {getBootstrapData('city').map((item, i) => {
                        let selected = parseInt(item.id) === parseInt(props.defaultValue);
                        return <option key={i} value={parseInt(item.id)}
                                       selected={selected}>{item.name}</option>;
                    }
                )}
            </Form.Control>}
        </Form.Group>
    );
}

PropertyCitySelect.propTypes = {
    controlId: PropTypes.string,
    isSingleSelect: PropTypes.bool,
    countyFilter: PropTypes.number,
    onChange: PropTypes.func,
    emptyText: PropTypes.string,
    defaultValue: PropTypes.string
};
PropertyCitySelect.defaultProps = {
    controlId: "formPropertyCity",
    countyFilter: null,
    isSingleSelect: false,
    onChange: (_) => {
    },
    emptyText: "Nincs kiválasztva",
    defaultValue: null
};

export default PropertyCitySelect;
