import React, {useContext} from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BootstrapContext} from "../context/BootstrapContext";
import {useTranslation} from "react-i18next";

const PropertyExtrasPanel = (props) => {
    const { t } = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);
    const property = props.item;

    const getExtraById = (id) => {
        let item = getBootstrapData("extras").find(i => i.id === id);
        return item ? t("extras."+item.name,{ ns: 'property_fields' }) : "";
    }
    return (
        <Card className="bg-secondary ml-3 ml-md-0 mt-3 mb-3">
            <Card.Header>
                <Card.Title>{t('extras_header_label',{ ns: 'property_fields' })}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Container>
                        <Row className="w-100">
                            {property.extras.map((item_id, i) =>
                                (<Col key={i} md="12">{getExtraById(item_id)}</Col>)
                            )}
                        </Row>
                    </Container>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}


export default PropertyExtrasPanel;