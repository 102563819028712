import React, {useContext} from 'react';
import Card from "react-bootstrap/Card";
import {PropertyCardHeader, PropertyQuickDetails} from "../pages/Details";
import {LoadingContext} from "../components/context/LoadingContext";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import ReactGA from "react-ga4";
import {useTranslation} from "react-i18next";

export const SearchResultItemCard = (props) => {
    const {t} = useTranslation();
    const {requestLoadScreen} = useContext(LoadingContext);
    const item = props.item;
    const from_page = props.from_page;
    const propertyLink = "/property/" + item.id;
    const classNameString ="m-1 bg-secondary propertyListCard "+props.className;
    const hasImage = props.item.property_images.length > 0;

    const onCardClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        requestLoadScreen();
	ReactGA.event("show_property", {"property_id": item.id, "source_page": from_page});
        window.location.pathname = propertyLink;
    }
    return (
        <Card className={classNameString}  onClick={onCardClick}>
             <Card.Header>
                <PropertyCardHeader property={item} />
            </Card.Header>
            {hasImage && <Card.Img alt="" className="max-vw-100" src={item.property_images[0].src} loading="lazy"/>}
            <Card.Body>
                <Card.Title>{item.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.address_short}</Card.Subtitle>
                <Card.Text>
                    &nbsp;
                    <Card.Link className="float-right btn btn-success d-inline-block"
                               href={propertyLink}>{t('property_details')}</Card.Link>
                </Card.Text>
            </Card.Body>
            <Card.Footer className="p-1 small text-truncate ">
                <PropertyQuickDetails property={item} reduced={props.reduced}/>
            </Card.Footer>
        </Card>
    );
}
SearchResultItemCard.defaultProps={
    className: "",
    from_page: "search",
    reduced: false
}
SearchResultItemCard.propTypes = {
    className: PropTypes.string,
    item: PropTypes.object.isRequired,
    from_page: PropTypes.string,
    reduced: PropTypes.bool
};
const SearchResultList = (props) => {
    return (
        <Container className='align-items-md-stretch d-flex flex-wrap m-auto justify-content-space-evenly'>
            {props.items.map((item, key) =>
                <SearchResultItemCard key={item.id} item={item} reduced={true}/>
            )}
        </Container>
    );
}

export default SearchResultList;