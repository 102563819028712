import {Map, GoogleApiWrapper, Circle} from 'google-maps-react-17';
import React, {Component} from "react";
import { Trans } from 'react-i18next';

class MapContainer extends Component {
    constructor(props) {
        super(props);
        if (props.item.address.latitude !== undefined && props.item.address.longitude !== undefined) {
            this.state = {
                hasMap: true,
                lat: props.item.address.latitude,
                lng: props.item.address.longitude
            }
        } else {
            this.state = {hasMap: false};
        }
    }

    render() {
        return (
            <>
                {this.state.hasMap ? (
                    <Map containerStyle={{position: "relative", height: "50vh"}}
                         google={this.props.google}
                         zoom={14}
                         initialCenter={{
                             lat: this.state.lat,
                             lng: this.state.lng
                         }}>
                        <Circle
                            center={{
                                lat: this.state.lat,
                                lng: this.state.lng
                            }}
                            radius={500}
                            options={{
                                strokeColor: "#ff0000"
                            }}
                        />
                    </Map>
                ) : (
                    <Trans i18nKey="no_map_info">Nincs térkép információ</Trans>
                )}
            </>
        );
    }
}

export default GoogleApiWrapper((props) => ({
    apiKey: props.apiKey
}))(MapContainer)