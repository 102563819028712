import React, {useContext} from "react";
import {BootstrapContext} from "../components/context/BootstrapContext";
import { useTranslation } from "react-i18next";

export const getIdText = (id) => {
    return "MH" + (id + 1000);
}

export const getTargetPrice = (price) => {
    if (price>1000000) {
        return (price / 1000000).toFixed(2) + " mFt.";
    } else {
        return (price / 1000).toFixed(2) + " eFt.";
    }

}

export const PremiseTypeText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);
    const { t } = useTranslation();

    const typeText = (i) => {
        let item = getBootstrapData('premise_type').find(x => x.id === parseInt(i));
        return item ? item.name : "????";
    }

    return (
        <>
            {t(typeText(props.type_id),{ ns: 'property_fields' })}
        </>
    );
}

export const PropertyTypeText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);
    const { t } = useTranslation();

    const typeText = (i) => {
        let item = getBootstrapData('property_type').find(x => x.id === parseInt(i));
        return item ? item.name : "????";
    }

    return (
        <>
            {t("property_type."+typeText(props.type_id),{ ns: 'property_fields' })}
        </>
    );
}

export const RoomText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);

    const countRooms = (premises) => {
        return premises
            .map(r => getBootstrapData('premise_type').find(pt => pt.id === r.premise_type_id))
            .filter(p => p.count_as_room).length;
    }
    const countHalfRooms = (premises) => {
        return premises
            .map(r => getBootstrapData('premise_type').find(pt => pt.id === r.premise_type_id))
            .filter(p => p.count_as_halfroom).length;
    }

    const roomCount = () => countRooms(props.rooms);
    const halfRoomCount = () => countHalfRooms(props.rooms);

    return (
        <>
            {roomCount()}
            {halfRoomCount() !== 0 && (<> + {halfRoomCount()} fél</>)}
        </>
    );
}
