import React, {createContext, useEffect, useState} from 'react';
import {publicFetch} from "../../utils/fetch";
import LoadingScreen from "../LoadingScreen";

const BootstrapContext = createContext();
const {Provider} = BootstrapContext;

const bootstrapStorage = window.sessionStorage;

const BootstrapProvider = ({children}) => {
    const [init, setInit] = useState(true);
    const [bsData, setBsData] = useState([]);

    const getBootstrapData = (key) => {
        return bsData[key]
    };
    const isDataLoaded = () => {
        return bsData.length !== 0;
    };


    const load = () => publicFetch.get('/bootstrap', {})
        .then(resp => resp.data)
        .then(bsData => {
            setBsData(bsData);
            bootstrapStorage.setItem('bootstrapData',JSON.stringify(bsData));
        })
        .catch(error => {
        });

    useEffect(() =>{
        if (init) {
            setInit(false);
            let bootData = bootstrapStorage.getItem('bootstrapData');
            if (bootData !== null) {
                setBsData(JSON.parse(bootData));
            } else {
                load();
            }
        }
    }, [init])

    return (
        <Provider
            value={{
                getBootstrapData: key => getBootstrapData(key)
            }}>
            {!isDataLoaded() ? (
                <LoadingScreen/>
            ) : (
                <>
                    {children}
                </>
            )}
        </Provider>
    );
};


export {BootstrapContext, BootstrapProvider};