import React from 'react';

import Form from 'react-bootstrap/Form'
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import PropertyTypeSelect from "../components/selectors/PropertyTypeSelect";
import PropertyCitySelect from "../components/selectors/PropertyCitySelect";
import PropertyRangeSelector from "../components/selectors/PropertyRangeSelector";
import BuildingTypeSelect from "../components/selectors/BuildingTypeSelect";
import PropertyStateSelect from "../components/selectors/BuildingStateSelect";
import {useSearchParams} from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

const SearchPanelVertical = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const params = Object.fromEntries([...searchParams]);

    const handleSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();
	ReactGA.event("execute_search", {
            "filter_building_type": (params.building_type_id!==undefined && params.building_type_id!==""),
            "filter_prop_state_inner": (params.property_state_inner_id!==undefined && params.property_state_inner_id!==""),
            "filter_prop_state_outer": (params.property_state_outer_id!==undefined && params.property_state_outer_id!==""),
            "filter_prop_type": (params.property_type_id!==undefined && params.property_type_id!==""),
            "filter_city": (params.city_id!==undefined && params.city_id!==""),
            "filter_price": ((params.price_min!==undefined && params.price_min!=="") || (params.price_max!==undefined && params.price_max!=="")),
            "filter_size": ((params.size_min!==undefined && params.size_min!=="") || (params.size_max!==undefined && params.size_max!=="")),
            "filter_room_count": ((params.room_count_min!==undefined && params.room_count_min!=="") || (params.room_count_max!==undefined && params.room_count_max!=="")),
            "filter_plot_size": ((params.plot_size_min!==undefined && params.plot_size_min!=="") || (params.plot_size_max!==undefined && params.plot_size_max!==""))
        })
        window.location.reload();
    }

    const onBuildingTypeChange = (newValues) => {
        params.building_type_id=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onPropertyStateChange = (newValues) => {
        params.property_state_inner_id=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onPropertyTypeChange = (newValues) => {
        params.property_type_id=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onCityChange = (newValues) => {
        params.city_id=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    const onPriceMinChange = (newValues) => {
        params.price_min=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    const onPriceMaxChange = (newValues) => {
        params.price_max=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    const onSizeMinChange = (newValues) => {
        params.szze_min=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onSizeMaxChange = (newValues) => {
        params.size_max=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    const onRoomCountMinChange = (newValues) => {
        params.room_count_min=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onRoomCountMaxChange =(newValues) => {
        params.room_count_max=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    const onPlotSizeMinChange =(newValues) => {
        params.plot_size_min=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }
    const onPlotSizeMaxChange = (newValues) => {
        params.plot_size_max=newValues;
        var newQuery = new URLSearchParams(params);
        setSearchParams(newQuery);
    }

    return (
<>
            <Card className="bg-success text-white">
                <Card.Header>
                    <Card.Title>{t('search')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form action="#">
                        <BuildingTypeSelect defaultValue={(params.building_type_id!==undefined)?params.building_type_id:null}
                                            onChange={onBuildingTypeChange}
                                            controlId="building_type_id"/>
                        <PropertyStateSelect defaultValue={params.property_state_inner_id}
                                             onChange={onPropertyStateChange}
                                             controlId="property_state_inner_id"/>
                        <PropertyTypeSelect defaultValue={params.property_type_id}
                                            onChange={onPropertyTypeChange}
                                            controlId="property_type_id"/>
                        <PropertyCitySelect defaultValue={params.city_id}
                                            onChange={onCityChange}
                                            controlId="city"/>
                        <PropertyRangeSelector controlId="price"
                                               label={t('price')}
                                               defaultValueMin={params.price_min}
                                               defaultValueMax={params.price_max}
                                               onMinChange={onPriceMinChange}
                                               onMaxChange={onPriceMaxChange}
                                               unit="m.Ft"/>
                        <PropertyRangeSelector controlId="size"
                                               label={t('size')}
                                               defaultValueMin={params.size_min}
                                               defaultValueMax={params.size_max}
                                               onMinChange={onSizeMinChange}
                                               onMaxChange={onSizeMaxChange}
                                               unit="m2"/>
                        <PropertyRangeSelector controlId="room_count"
                                               label={t('room_count')}
                                               defaultValueMin={params.room_count_min}
                                               defaultValueMax={params.room_count_max}
                                               onMinChange={onRoomCountMinChange}
                                               onMaxChange={onRoomCountMaxChange}
                                               unit="db"/>
                        <PropertyRangeSelector controlId="plot_size"
                                               label={t('plot_size')}
                                               defaultValueMin={params.plot_size_min}
                                               defaultValueMax={params.plot_size_max}
                                               onMinChange={onPlotSizeMinChange}
                                               onMaxChange={onPlotSizeMaxChange}
                                               unit="m2"/>
                        <Button onClick={handleSearch} variant="light" type="button">
                            {t('search')}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
       </>
    );
}

export default SearchPanelVertical;