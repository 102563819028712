import Form from "react-bootstrap/Form";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {BootstrapContext} from "../context/BootstrapContext";
import InputGroup from "react-bootstrap/InputGroup";
import {Multiselect} from 'multiselect-react-dropdown';
import {useTranslation} from "react-i18next";

const BuildingTypeSelect = (props) => {
    const { t } = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);
    const isMultiline = !props.isSingleSelect;
    const [selected, setSelected] = useState(null);
    const onMultiSelectChange = (selectedList, __) => {
        setSelected(selectedList);
        props.onChange(selectedList.map(item => item.id).join(","));
    }
    const onSingleSelectChange = (e) => {
        props.onChange(e.target.value);
    }

    useEffect(() => {
        if (props.defaultValue === undefined || props.defaultValue === null)
            return;
        if (isMultiline) {
            if (selected === null) {
                let sel = props.defaultValue.split(",").map(i => parseInt(i));
                let newSelect = getBootstrapData('building_type').filter(city => sel.includes(city.id));
                setSelected(newSelect);
            }
        }

    }, [isMultiline, selected, props.defaultValue, getBootstrapData]);

    return (
        <Form.Group controlId={props.controlId}>
            <InputGroup>
                <Form.Label>{t('wall_type')}</Form.Label>
                {isMultiline &&
                <Multiselect
                    classNane="bg-secondary"
                    options={getBootstrapData('building_type')} // Options to display in the dropdown
                    selectedValues={selected} // Preselected value to persist in dropdown
                    onSelect={onMultiSelectChange} // Function will trigger on select event
                    onRemove={onMultiSelectChange} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                />
                }
                {!isMultiline &&
                <Form.Control componentClass="select"
                              name={props.controlId}
                              onChange={onSingleSelectChange}
                              defaultValue={parseInt(props.defaultValue)}>
                    {!props.defaultValue && <option key="0" value="">{t(props.emptyText)}</option>}
                    {getBootstrapData('building_type').map((item, i) => {
                            let selected = parseInt(item.id) === parseInt(props.defaultValue);
                            return <option key={i} value={parseInt(item.id)}
                                           selected={selected}>{t('building_type.'+item.name,{'ns':'property_fields'})}</option>;
                        }
                    )}
                </Form.Control>}
            </InputGroup>
        </Form.Group>
    );
}
BuildingTypeSelect.propTypes = {
    controlId: PropTypes.string,
    isSingleSelect: PropTypes.bool,
    onChange: PropTypes.func,
    emptyText: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
};
BuildingTypeSelect.defaultProps = {
    controlId: "formBuildingType",
    isSingleSelect: false,
    onChange: (_) => {
    },
    emptyText: "Nincs kiválasztva",
    defaultValue: null
};

export default BuildingTypeSelect;
