import React, {useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import {publicFetch} from "../utils/fetch";
import LoadingScreen from "../components/LoadingScreen";
import {SearchResultItemCard} from "./SearchResultList";
import {useTranslation} from "react-i18next";

const HomeSuggestionsCarousel = (props) => {
    return (
        <Container className="align-items-md-stretch d-flex flex-wrap m-auto justify-content-space-evenly suggestions">
            {props.items.map((i, si) => (
                <SearchResultItemCard key={si} item={i} from_page="suggestions" reduced={true}/>
            ))
            }
        </Container>

    );
}
const Suggestions = (props) => {
    const {t} = useTranslation();
    const [isInit, setIsInit] = useState(true);
    const [isPropertyLoading, setIsPropertyLoading] = useState(true);
    const [isPropertyError, setIsPropertyError] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (isInit) {
            loadFromServer();
            setIsInit(false);
        }
    }, [isInit]);


    const loadFromServer = async () => {
        publicFetch
            .get("/property/favored", {})
            .then(response => {
                return response.data
            })
            .then(ps => {
                setItems(ps);
                setIsPropertyError(false);
                setIsPropertyLoading(false);
            })
            .catch(error => {
                setIsPropertyError(true);
                setIsPropertyLoading(false);
            });
    }
    return (
        <Container fluid="true" className="">
            <Row className="w-100 m-0 p-0">
                <Col md="12" className="gradient">
                    <h5 className="text-white p-3 pt-2">{t('suggestions')}</h5>
                </Col>
            </Row>
            <Row className="w-100 m-0">
                <Col md="12">
                    {isPropertyLoading ? (
                        <LoadingScreen/>
                    ) : (
                        <>
                            {isPropertyError ? (
                                <>Hiba</>
                            ) : (
                                <HomeSuggestionsCarousel items={items}/>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default Suggestions;