import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {LoadingContext} from "../components/context/LoadingContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const Services = () => {
    const { t } = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(()=> {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("services.title", {ns:"seo"})}</title>
                <meta
                    name="keywords"
                    content={t("services.keywords", {ns:"seo"})}
                />
                <meta
                    name="description"
                    content={t("services.description", {ns:"seo"})}
                />
            </Helmet>
            <Navigation/>
            <div className="bg-image2 w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
            <Container>
                <Row className="m-3">
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="bg-secondary">
                            <Card.Header className="font-weight-bolder text-center">
                                <h4>Szolgáltatásaink</h4>
                            </Card.Header>
                            <Card.Body>
                                <div className="kapcsolat-bg">
                                    <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li"><i className="fas fa-home"/> </span>
                                            Ingatlanközvetítés
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-gavel"/> </span>
                                            Ügyvédi háttér
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-hand-holding-usd"/> </span>
                                            Hitel ügyintézés
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-house-damage"/> </span>
                                            Biztosítás
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="far fa-lightbulb"/> </span>
                                            Energetikai tanúsítvány
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-handshake"/> </span>
                                                Ingyenes tanácsadás
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-camera"/> </span>
                                            Profi fotó
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-edit"/> </span>
                                            Belső látványterv
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-vr-cardboard"/> </span>
                                            3D-s virtuális séta
                                        </li>
                                        <li>
                                            <span className="fa-li"><i className="fas fa-street-view"/> </span>
                                            Drónfelvétel
                                        </li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Services;