import React, {useCallback, useContext, useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navigation from "../containers/Navigation";
import DetailedDescriptionPanel from "../components/property-details/DetailedDescriptionPanel";
import ContactCard from "../components/ContactCard";
import {publicFetch} from "../utils/fetch";
import PropertyImagesCorosel from "../components/property-details/PropertyImagesCarosel";
import Col from "react-bootstrap/Col";
import LoadingScreen from "../components/LoadingScreen";
import PropertyDetailsPanel from "../components/property-details/PropertyDetailsPanel";
import Card from "react-bootstrap/Card";
import PropertyExtrasPanel from "../components/property-details/PropertyExtrasPanel";
import {getIdText, getTargetPrice, PropertyTypeText, RoomText} from "../utils/property_tools";
import MapContainer from "../components/MapContainer";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import {BootstrapContext} from "../components/context/BootstrapContext";
import ContactForm from "../components/ContactForm";
import {LoadingContext} from "../components/context/LoadingContext";
import img360 from "../images/360-degree-rotate.svg"
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
    EmailIcon,
    EmailShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton, ViberIcon,
    ViberShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import Modal from "react-bootstrap/Modal";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";


const SharePopupModal = (props) => {
    const {t} = useTranslation();
    const hasURL = () => {
        return props.url !== null;
    }
    return (
        <>
            {hasURL() && <Modal
                size="m"
                show={hasURL()}
                onHide={() => props.onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('share')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="position-relative">
                    <EmailShareButton subject="Nézd meg ezt az ingatlant!">
                        <EmailIcon size={32} round={true}/>
                    </EmailShareButton>
                    <FacebookMessengerShareButton appId="1362240274168559" url={props.url}><FacebookMessengerIcon
                        size={32} round={true}/></FacebookMessengerShareButton>
                    <ViberShareButton><ViberIcon size={32} round={true}/></ViberShareButton>
                    <WhatsappShareButton><WhatsappIcon size={32} round={true}/></WhatsappShareButton>
                </Modal.Body>
            </Modal>}
        </>
    );
}

export const PropertyQuickDetails = (props) => {
    const {t} = useTranslation();
    const mdWidth = 3;
    const smWidth = props.reduced ? 4 : 6;

    return (
        <Container className="m-0" fluid="true">
            <Row>
                <Col md={mdWidth} sm={smWidth} xs={smWidth}>
                    {t('living_area')}<br/>
                    {props.property.living_size} m<sup>2</sup>
                </Col>
                {props.property.plot && props.property.plot.size && <Col md={mdWidth} sm={smWidth} xs={smWidth}>
                    {t('plot_size')}<br/>
                    {props.property.plot.size} m<sup>2</sup>
                </Col>}
                {props.property.premises && <Col md={mdWidth} sm={smWidth} xs={smWidth}>
                    {t('rooms')}<br/>
                    <RoomText rooms={props.property.premises}/>
                </Col>}
                <Col md={mdWidth} sm={smWidth} xs={smWidth}>
                    {t('price')}<br/>
                    {getTargetPrice(props.property.commission.target_price)}
                </Col>
            </Row>
        </Container>
    );
}
PropertyQuickDetails.propTypes = {
    property: PropTypes.object.isRequired,
    reduced: PropTypes.bool
}
PropertyQuickDetails.defaultProps = {
    reduced: false
}

export const PropertyFlagsBadges = (props) => {
    const {t} = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);


    const flagText = (i) => {
        let item = getBootstrapData('flags').find(x => x.id === parseInt(i));
        return item ? t("flags." + item.name, {ns: 'property_fields'}) : "????";
    }
    return (
        <>
            {props.property.flags &&
            <>
                {props.property.flags.map((flag, i) => (
                    <Badge key={i} className="badge-info">{flagText(flag)}</Badge>
                ))}

            </>
            }
        </>
    );
}

PropertyFlagsBadges.propTypes = {
    property: PropTypes.object.isRequired
}
PropertyFlagsBadges.defaultProps = {}

export const PropertyCardHeader = (props) => {
    return (
        <>
            <Card.Title>
                {props.property.purpose === 'for_sale' ?
                    (<Trans i18nKey="sth_for_sale">Eladó <PropertyTypeText
                        type_id={props.property.property_type_id}/></Trans>) :
                    (<Trans i18nKey="sth_for_rent">Kiadó <PropertyTypeText
                        type_id={props.property.property_type_id}/></Trans>)
                }
                <span className="float-right">{getIdText(props.property.id)}</span>
            </Card.Title>
            <Card.Subtitle>
                <PropertyFlagsBadges property={props.property}/>
            </Card.Subtitle>
        </>
    );
}

PropertyCardHeader.propTypes = {
    property: PropTypes.object.isRequired
}
PropertyCardHeader.defaultProps = {}

function MapPanel(props) {
    const {t} = useTranslation();
    return <Card className={"bg-secondary ml-3 ml-md-0 mb-3 mt-md-3 " + props.className}>
        <Card.Header>
            <Card.Title>{t('map')}</Card.Title>
        </Card.Header>
        <Card.Body className="p-1 justify-content-center">
            <MapContainer item={props.item}
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}/>
        </Card.Body>
    </Card>;
}

function InteractionButtons(props) {
    const {t} = useTranslation();
    const virtualTourCallback = (e) => {
        e.preventDefault();
        ReactGA.event("property_interact", {"property_id": props.item.id, "action": "open_tour"});
        window.open(props.item.virtual_tour, "virtual_tour");
    }
    const videoTourCallback = (e) => {
        e.preventDefault();
        ReactGA.event("property_interact", {"property_id": props.item.id, "action": "open_video"});
        window.open(props.item.video_tour, "video_tour");
    }
    const pdfDownloadCallback = (e) => {
        e.preventDefault();
        ReactGA.event("property_interact", {"property_id": props.item.id, "action": "download_pdf"});
        window.open("https://magichouse-property-documents.s3.eu-west-3.amazonaws.com/" + getIdText(props.item.id) + ".pdf", "_blank");
    }
    const vTourText = props.item.virtual_tour ?
        t("virtual_walk_available") :
        t("virtual_walk_not_available");
    const resultClass = "bg-secondary ml-3 ml-md-0 mb-3 mt-0 mt-md-3 " + props.className;
    return <Card className={resultClass}>
        <Card.Body className="justify-content-space-evenly">
            <OverlayTrigger overlay={<Tooltip id="tooltip-img360">{vTourText}</Tooltip>}>
                {props.item.virtual_tour ?
                    (<Button className="w-100" onClick={virtualTourCallback}><img style={{
                        width: "32px",
                        height: "16px"
                    }} src={img360} alt={t('virtual_walk')}/> {t('virtual_walk')}</Button>) :
                    (<Button className="w-100 bg-light feature-inactive disabled={true}">
                        <img style={{
                            width: "32px",
                            height: "16px"
                        }} src={img360} alt={t('virtual_walk')}/> {t('virtual_walk')}</Button>)
                }
            </OverlayTrigger>
            <br/>
            <OverlayTrigger overlay={<Tooltip id="tooltip-img-youtube">Youtube bemutató</Tooltip>}>
                {props.item.video_tour ?
                    (<Button className="w-100" onClick={videoTourCallback}>
                        <i className="fab fa-youtube"></i>{t('video')}</Button>) :
                    (<Button className="w-100 bg-light feature-inactive" disabled={true}>
                        <i className="fab fa-youtube"></i> {t('video')}</Button>)
                }
            </OverlayTrigger><br/>
            <OverlayTrigger overlay={<Tooltip id="tooltip-img-pdf">PDF nyomtatás</Tooltip>}>
                <Button className="w-100 bg-light" onClick={pdfDownloadCallback}>
                    <i className="fas fa-print"></i>{t('print')} </Button>
            </OverlayTrigger><br/>
            <OverlayTrigger overlay={<Tooltip id="tooltip-img-share">Megosztás</Tooltip>}>
                <Button className="w-100 bg-light" onClick={props.shareButtonCallback}>
                    <i className="fas fa-share-alt-square"></i>{t('share')} </Button>
            </OverlayTrigger>
        </Card.Body>
    </Card>;
}

const Details = (props) => {
    let {propertyId} = useParams();
    const {t} = useTranslation();
    const [isInit, setIsInit] = useState(true);
    const [isPropertyLoading, setIsPropertyLoading] = useState(true)
    const [isPropertyError, setIsPropertyError] = useState(true)
    const [isPropertyDeleted, setIsPropertyDeleted] = useState(true)
    const [property, setProperty] = useState(null);
    const {completeLoad} = useContext(LoadingContext);
    const [shareURL, setShareURL] = useState(null);

    const shareButtonCallback = () => {
        ReactGA.event("property_interact", {"property_id": propertyId, "action": "share"});
        setShareURL("https://www.magichouse.hu/property/" + propertyId)
    }
    const shareModalCloseCallback = () => {
        setShareURL(null)
    }
    const loadFromServer = useCallback(async () => {
        publicFetch
            .get("/property/" + propertyId, {})
            .then(response => {
                return response.data
            })
            .then(prop => {
                setProperty(prop);
                setIsPropertyLoading(false)
                setIsPropertyError(false);

                setIsPropertyDeleted(false);
                completeLoad();
            })
            .catch(error => {
                console.log(error);
                setIsPropertyLoading(false);
                if (error.response.status == 410) {

                    setIsPropertyError(false);
                    setIsPropertyDeleted(true);
                } else {

                    setIsPropertyError(true);
                    setIsPropertyDeleted(false);
                }
                completeLoad();
            });
    }, [completeLoad, propertyId])

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            loadFromServer()
        }
    }, [isInit, loadFromServer])

    const getDescription = (inputText) => {
        console.log(inputText)
        return inputText
    }


    return (
        <>
            <Navigation/>
            <SharePopupModal url={shareURL} onHide={shareModalCloseCallback}/>
            <Container fluid="true">
                <Row className="w-100 mr-0 ml-0">
                    <Col xl="12" xs="12" className="pr-0">
                        {!isPropertyLoading ? (
                            <>{
                                isPropertyDeleted ? (
                                    <div className="text-center text-danger m-5">{t('property_already_disabled')}</div>
                                ) : (
                                    <>{!isPropertyError ? (
                                        <>
                                            <Helmet>
                                                <meta charSet="utf-8"/>
                                                <title>{property.title}</title>
                                                <meta
                                                    name="description"
                                                    content={getDescription(property.description)}
                                                />
                                            </Helmet>
                                            <Container fluid="true">
                                                <Row className="w-100">
                                                    <Col md="8" xs="12" className="pr-0">
                                                        <Card className=" bg-secondary m-3">
                                                            <Card.Header>
                                                                <PropertyCardHeader property={property}/>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Card.Text as="div">
                                                                    {property.title}<br/>
                                                                    {property.address.address_approximate}<br/>
                                                                    <PropertyImagesCorosel property_id={property.id}/>
                                                                </Card.Text>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <PropertyQuickDetails property={property}/>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                    <Col md="4" xs="12">
                                                        <InteractionButtons item={property}
                                                                            shareButtonCallback={shareButtonCallback}
                                                                            className="d-block d-md-none"/>
                                                        <MapPanel item={property} className="d-none d-md-block"/>
                                                        <InteractionButtons item={property}
                                                                            shareButtonCallback={shareButtonCallback}
                                                                            className="d-none d-md-block"/>
                                                    </Col>
                                                </Row>
                                                <Row className="w-100">
                                                    <Col xs="12" md="8">
                                                        <DetailedDescriptionPanel item={property}/>

                                                    </Col>
                                                    <Col xs="12" md="4">
                                                        <PropertyDetailsPanel item={property}/>
                                                        <PropertyExtrasPanel item={property}/>

                                                    </Col>
                                                    <Col xs="12" md="4">
                                                        <MapPanel item={property} className="d-block d-md-none"/>
                                                        <ContactCard item={property.contact}
                                                                     className="d-block d-md-none"/>
                                                        <ContactForm property_id={property.id}
                                                                     className="d-block d-md-none"/>
                                                    </Col>
                                                </Row>
                                                <Row className="pl-3 w-100">
                                                    <Col md="6">
                                                        <ContactCard item={property.contact}
                                                                     className="d-none d-md-block mt-3"/>
                                                    </Col>
                                                    <Col md="6">
                                                        <ContactForm property_id={property.id}
                                                                     className="d-none d-md-block"/>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </>
                                    ) : (
                                        <div className="text-center text-danger m-5">{t('property_not_found')}</div>
                                    )}
                                    </>
                                )
                            }

                            </>
                        ) : (
                            <LoadingScreen/>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Details;