import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {LoadingContext} from "../components/context/LoadingContext";
import MZ from "../images/MagicZoli.JPG";
import MD from "../images/MagicDetti.jpg";
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from "react-helmet";

const About = () => {
    const {t} = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(() => {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("about_page.title", {ns:"seo"})}</title>
                <meta
                    name="keywords"
                    content={t("about_page.keywords", {ns:"seo"})}
                />
                <meta
                    name="description"
                    content={t("about_page.description", {ns:"seo"})}
                />
            </Helmet>
            <Navigation/>
            <div className="bg-image3 w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
            <Container>
                <Row className="m-3">
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="bg-secondary">
                            <Card.Header className="font-weight-bolder text-center">
                                <h4><Trans i18nKey="welcome_page_header" ns="about_us">
                                    Üdvözöljük a <span className="MHFont">Magic House</span> ingatlaniroda honlapján!
                                </Trans></h4>
                            </Card.Header>
                            <Card.Body>
                                <p>{t('welcome_text',{ ns: 'about_us' })}</p><br/>
                                <Row>
                                    <Col>
                                        <p> {t('services',{ ns: 'about_us' })}:</p>
                                        <ul className="fa-ul">
                                            <li>
                                                <span className="fa-li"><i className="fas fa-home"></i></span>
                                                {t('real_estate_brokerage',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-gavel"></i></span>
                                                {t('lawyer_help',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i
                                                    className="fas fa-hand-holding-usd"></i></span>
                                                {t('mortgage_help',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-house-damage"></i> </span>
                                                {t('insurance_help',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="far fa-lightbulb"></i></span>
                                                {t('energy_certificate',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                            <span className="fa-li"><i className="fas fa-handshake"></i>
                                                <i className="fas fa-calculator"></i></span>
                                                {t('free_consultation',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-camera"></i></span>
                                                {t('professional_photo',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-edit"></i></span>
                                                {t('interior_design',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-vr-cardboard"></i></span>
                                                {t('3D_virtual_walk',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fas fa-street-view"></i></span>
                                                {t('drone_footage',{ ns: 'about_us' })}
                                            </li>
                                            <li>
                                                <span className="fa-li"><i
                                                    className="fas fa-solid fa-language"></i></span>
                                                {t('english_language_assistance',{ ns: 'about_us' })}
                                            </li>
                                        </ul>
                                        <p> {t('thanks',{ ns: 'about_us' })}</p>
                                        <p>{t('MH_agency',{ ns: 'about_us' })}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <img className="w-100" src={MZ} alt={t("Balogh Zoltán érdi ingatlanközvetítő",{ ns: 'about_us' })}/>
                                        <p>Balogh Zoltán</p>
                                    </Col>
                                    <Col>
                                        <img className="w-100" src={MD} alt={t("Balogh-Bor Bernadett érdi ingatlanügynök",{ ns: 'about_us' })}/>
                                        <p>Balogh-Bor Bernadett</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default About;