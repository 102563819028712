import React, {createContext} from 'react';
// import logo from "../../images/MH_logo_outline.svg";

const LoadingContext = createContext();
const {Provider} = LoadingContext;


const LoadingProvider = ({children}) => {
    const loadingStore = window.localStorage;
    const leftSlider = React.createRef();
    const rightSlider = React.createRef();

    const completeLoad = () => {
        if (leftSlider.current) {
            leftSlider.current.classList.add('out');
        }

        if (rightSlider.current) {
            rightSlider.current.classList.add('out');
        }
        loadingStore.setItem('loadRequired', 'false');
    }

    const isLoadRequired = () => {
        return loadingStore.getItem("loadRequired") !== 'false';
    }

    const requestLoadScreen = () => {
        loadingStore.setItem('loadRequired', 'true');
    }

    return (
        <Provider
            value={{
                completeLoad,
                requestLoadScreen
            }}>
            {isLoadRequired() && <div id="slider-left" className="slider" ref={leftSlider}>
                <div className="position-relative" style={{
                    height: '200px',
                    width: '300px',
                    top: '40%',
                    left: '100%',
                    textAlign: 'center',
                    WebkitTransform: 'translate(-150px, -100px)'
                }}>
                    <img src="/MH_logo_outline.svg" style={{
                        height: '200px'
                    }} alt=""/>
                </div>
            </div>}
            {isLoadRequired() && <div id="slider-right" className="slider" ref={rightSlider}>&nbsp;</div>}
            <>
                {children}
            </>
        </Provider>
    );
};


export {LoadingContext, LoadingProvider};