import React, {useState} from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import {getIdText} from "../utils/property_tools";
import Button from "react-bootstrap/Button";
import {publicFetch} from "../utils/fetch";
import {useTranslation} from "react-i18next";
// import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'

const ContactForm = (props) => {
    const { t } = useTranslation();
    const property_id = props.property_id;
    const [received, setReceived] = useState(false);
    const [unavailable, setUnavailable] = useState(false);
    const contactRef = React.createRef();
    const messageRef = React.createRef();
    const privacyRef = React.createRef();

    const onFormSubmit = (e) => {
        let contact = contactRef.current.value;
        let message = messageRef.current.value;
        let privacyAccepted = privacyRef.current.checked;
        if (contact.length<5){
            alert(t("Kérjük ellenőrizze az Ön által megadott elérhetőséget"));
            return false;
        }
        if (privacyAccepted!==true){
            alert(t("Kérem jelölje be a jelölőnégyzeten, hogy az Ön által megadott elérhetőséget rendszerünk tárolhassa kapcsolatfelvétel céljából."));
            return false;
        }

        let lead = new FormData();
        lead.append("contact", contact);
        lead.append("message", message);
        publicFetch
            .post("/property/" + property_id + "/leads", JSON.stringify(Object.fromEntries(lead)))
            .then(resp => {
                setReceived(true);
            })
            .catch(error => {
                setUnavailable(true)
            })
            .finally(() => {
                if (contactRef.current)
                    contactRef.current.value = "";
                if (messageRef.current)
                    messageRef.current.value = "";
            })
    }

    return (
        <Card className={"bg-secondary ml-3 ml-md-0 mt-3 " + props.className}>
            <Card.Header className="gradient2">
                <Card.Title className="font-bolder text-white">{t('write_us')}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1">
                <Card.Text as="div">
                    {received ? (
                        <div className="text-success p-3">{t('call_you')}</div>
                    ) : (
                        <>
                            {unavailable ? (
                                <div className="text-warning p-3">{t('warning_funtion')}</div>
                            ) : (
                                <Form>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="contact_detail">
                                                    <Form.Label>{t('property_code')}</Form.Label>
                                                    <Row>
                                                        <Form.Control inline="true"
                                                                      autoComplete="off"
                                                                      type="text"
                                                                      defaultValue={getIdText(property_id)}
                                                                      readOnly={true}
                                                                      size="sm"/>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="contact_detail">
                                                    <Form.Label>{t('your_contact')}</Form.Label>
                                                    <Row>
                                                        <Form.Control inline="true"
                                                                      autoComplete="off"
                                                                      type="text"
                                                                      defaultValue=""
                                                                      ref={contactRef}
                                                                      size="sm"/>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group controlId="contact_detail">
                                                    <Form.Label>{t('remark')}</Form.Label>
                                                    <Row>
                                                        <Form.Control autoComplete="off"
                                                                      as="textarea" rows="3"
                                                                      defaultValue=""
                                                                      ref={messageRef}
                                                                      size="sm"/>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group>
                                                    {/*<EllipsisWithTooltip placement="auto">*/}
                                                        <Form.Check inline ref={privacyRef} type={"checkbox"}/>{t('checkbox')}

                                                    {/*</EllipsisWithTooltip>*/}
                                                </Form.Group>
                                                <Button type="button" onClick={onFormSubmit}>{t('send')}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            )}
                        </>
                    )}
                </Card.Text>
            </Card.Body>
        </Card>
    )
        ;
}

export default ContactForm;