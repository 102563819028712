import React, {useCallback, useContext, useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Navigation from "../containers/Navigation";
import SearchResultList from "../containers/SearchResultList";
import {publicFetch} from "../utils/fetch";
import {LoadingContext} from "../components/context/LoadingContext";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {debounce} from "lodash";

const RentableProperties = (props) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [isInit, setIsInit] = useState(true);
    const [isPropertyLoading, setIsPropertyLoading] = useState(true);
    const [isPropertyError, setIsPropertyError] = useState(false);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize,] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const {completeLoad} = useContext(LoadingContext);

    const loadFromServer = useCallback(async () => {
        var aQuery = {}
        for (const entry of searchParams.entries()) {
            aQuery[entry[0]] = entry[1];
        }
        publicFetch
            .get("/property?property_purpose=for_rent&limit=" + pageSize + "&offset=" + page * pageSize)
            .then(response => {
                return response.data
            })
            .then(ps => {
                if (ps.length === 0) {
                    setHasMore(false);
                }
                setItems(items => [...items, ...ps]);
                setPage(page => page + 1);
                setIsPropertyError(false);
                setIsPropertyLoading(false);
                completeLoad();

            })
            .catch(error => {
                setIsPropertyError(true);
                setIsPropertyLoading(false);
                setHasMore(false);
                completeLoad();
            });
    }, [completeLoad, page, pageSize, searchParams])


    useEffect(() => {
        if (isInit) {
            loadFromServer();
            setHasMore(true);
            setIsInit(false);
        }
    }, [isInit, loadFromServer]);

    function isAtBottom() {
        const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const documentHeight = document.documentElement.scrollHeight;

        return scrollY + windowHeight >= documentHeight - 200;
    }

    window.onscroll = debounce(() => {
        if (isAtBottom() && !isPropertyLoading) {
            setIsPropertyLoading(true);
            loadFromServer();
        }
    }, 100);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("rentable_properties.title", {ns: "seo"})}</title>
                <meta
                    name="keywords"
                    content={t("rentable_properties.keywords", {ns: "seo"})}
                />
                <meta
                    name="description"
                    content={t("rentable_properties.description", {ns: "seo"})}
                />
            </Helmet>
            <Navigation/>
            <Container fluid="true">
                <Row className="w-100">
                    <Col md="12" className="pt-1 pr-0">
                        {isPropertyError ? (
                            <>Hiba</>
                        ) : (
                            <>
                                {items.length === 0 ? (
                                    <>
                                        {isPropertyLoading ? (
                                            <>{t('loading')}</>
                                        ) : (
                                            <Container className="align-content-center align-items-center">
                                                {t('No results have been found')}
                                            </Container>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <SearchResultList items={items}/>
                                        {isPropertyLoading && hasMore ? (
                                            <>{t('loading')}</>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default RentableProperties;