import Form from "react-bootstrap/Form";
import React from "react";

const PropertyPurposeSelect = () => {
    const choices = ["Eladó", "Kiadó"];
    const items = choices.map((number, index) =>
        <Form.Check type="radio" label={number} key={index}/>
    );

    return (
        <Form.Group controlId="formPropertyPurpose">
            <Form.Label>Cél</Form.Label>
            {items}
        </Form.Group>
    );
}

export default PropertyPurposeSelect;