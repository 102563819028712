import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {LoadingContext} from "../components/context/LoadingContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(() => {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("privacy_policy.title", {ns:"seo"})}</title>
                <meta
                    name="keywords"
                    content={t("privacy_policy.keywords", {ns:"seo"})}
                />
                <meta
                    name="description"
                    content={t("privacy_policy.description", {ns:"seo"})}
                />
            </Helmet>
            <Navigation/>
            <div className="bg-image3 w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
            <Container>
                <Row className="m-3">
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="bg-secondary">
                            <Card.Header className="font-weight-bolder text-center">
                                <h4>{t('privacy_policy')}</h4>
                                <Card.Subtitle>a szervezettel szerződéses kapcsolatban álló vállalkozások és más
                                    szervezetek részére
                                </Card.Subtitle>
                            </Card.Header>
                            <Card.Body className="privacy-document">
                                <h4>Bevezetés</h4>
                                <p>A szervezet - mint adatkezelő – szerződéses kapcsolatban áll vállalkozásokkal és
                                    egyéb szervezetekkel, amelyek a szervezet zavartalan működtetésében közreműködnek. A
                                    szervezet e szervezetek tisztségviselőinek és alkalmazottainak - érintett személyek
                                    - azon személyes adatait kezeli, amelyek kapcsolattartás céljából feltétlenül
                                    szükségesek.</p>
                                <p>A szervezet - mint adatkezelő - tevékenysége során teljes mértékben meg kíván felelni
                                    a személyes adatok kezelésére vonatkozó jogszabályi előírásoknak, különösen az
                                    Európai Parlament és a Tanács (EU) 2016/679 rendeletében foglaltaknak.</p>
                                <p>Ez az adatkezelési tájékoztató a természetes személyek személyes adatai védelméről és
                                    az adatok szabad áramlásáról az Európai Parlament és a Tanács (EU) 2016/679
                                    rendelete alapján készült, figyelemmel a 2011. évi CXII. törvény követelményeire,
                                    amely az információs önrendelkezési jogról és az információszabadságról szól.</p>
                                <p>A szervezet a jogszabályoknak megfelelően és saját belső szabályzatában rögzített
                                    elvek szerint kezeli a vele kapcsolatban álló természetes személyek adatait. A belső
                                    szabályzat betartásával a szervezet tisztségviselői és alkalmazottai a természetes
                                    személyek adatainak kezelését jogszerűen végzik.</p>
                                <p>A szervezet kiemelten fontosnak tartja a vele kapcsolatban álló személyek információs
                                    önrendelkezési jogának tiszteletben tartását, személyes adataikat bizalmasan kezeli,
                                    és betart minden olyan technikai és szervezési intézkedést, amely az adatok
                                    biztonságát garantálja.</p>
                                <p>Ez az adatkezelési tájékoztató az érintett személyek részére nyújt információt
                                    személyes adataik kezeléséről.</p>
                                <table>
                                    <caption>Az adatkezelő megnevezése, elérhetőségei:</caption>
                                    <tbody>
                                    <tr>
                                        <td>A szervezet megnevezése:</td>
                                        <td>BZ House Kft</td>
                                    </tr>
                                    <tr>
                                        <td>A szervezet címe:</td>
                                        <td>2030 Érd Irén utca 24</td>
                                    </tr>
                                    <tr>
                                        <td>Weboldal megnevezése, címe:</td>
                                        <td>www.magichouse.hu</td>
                                    </tr>
                                    <tr>
                                        <td>Levelezési cím:</td>
                                        <td>2030 Érd Bajcsy-Zsilinszky út 61</td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td>balogh.zoltan@magichouse.hu</td>
                                    </tr>
                                    <tr>
                                        <td>Telefon:</td>
                                        <td>0670-600-2393</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>Az adatkezelés irányelvei</h4>
                                <p>Az adatkezelő kijelenti, hogy az adatkezelési szabályzatában foglaltak szerint végzi
                                    a személyes adatok kezelését és betartja a vonatkozó jogszabályok előírásait,
                                    különös figyelemmel az alábbiakra:
                                </p>
                                <ul>
                                    <li>A személyes adatok kezelését jogszerűen és tisztességesen, valamint az érintett
                                        számára átlátható módon végzi.
                                    </li>
                                    <li>A személyes adatok kezelése csak meghatározott, egyértelmű és jogszerű célból
                                        történik. A konkrét cél ez estben a szerződéses partnerekkel történő
                                        kapcsolattartás.
                                    </li>
                                    <li>Az adatkezelés jogalapja az érintett személy hozzájárulása, illetve
                                        jogszabályban foglalt kötelezettség.
                                    </li>
                                    <li>Az adatkezelésben érintettek köre a szervezettel szerződéses kapcsolatban
                                        álló vállalkozások és egyéb szervezetek kapcsolattartó tisztségviselői és
                                        alkalmazottai.
                                    </li>
                                    <li>Az adatkezelés időtartama és az adatok törlése. Az adatkezelés időtartama
                                        mindig a konkrét felhasználói cél függvénye, de az adatokat haladéktalanul
                                        törölni kell, ha az eredetileg kitűzött cél már megvalósult. Az
                                        adatkezeléshez történő hozzájárulását az érintett személy bármikor
                                        visszavonhatja. Amennyiben a törlésnek jogszabályi akadálya nincs, ez
                                        esetben adatai törlésre kerülnek.
                                    </li>
                                    <li>Az adatok megismerésére jogosultak az adatkezelő és alkalmazottai.
                                    </li>
                                    <li>Az érintett személy kérelmezheti az adatkezelőtől a rá vonatkozó személyes
                                        adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének
                                        korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen.
                                    </li>
                                    <li>Az érintett személy bármely időpontban visszavonhatja az adatkezelési
                                        hozzájárulását, de ez nem változtatja meg a visszavonás előtt, hozzájárulás
                                        alapján végrehajtott adatkezelés jogszerűségét.
                                    </li>
                                    <li>Az érintett személy élhet a felügyeleti hatósághoz címzett panasz
                                        benyújtásának jogával.
                                    </li>
                                    <li>Az érintett személy jogosult arra, hogy kérésére az adatkezelő indokolatlan
                                        késedelem nélkül helyesbítse, illetve kiegészítse a rá vonatkozó pontatlan
                                        személyes adatokat.
                                    </li>
                                    <li>Az érintett személy jogosult arra, hogy kérésére az adatkezelő indokolatlan
                                        késedelem nélkül törölje a rá vonatkozó pontatlan személyes adatokat, az
                                        adatkezelő pedig köteles arra, hogy az érintettre vonatkozó személyes
                                        adatokat indokolatlan késedelem nélkül törölje, amennyiben az adatkezelésnek
                                        nincs más jogalapja.
                                    </li>
                                    <li><u>A személyes adatok módosítása vagy törlése kezdeményezhető e-mailben,
                                        telefonon vagy levélben a tájékoztatóban megadott elérhetőségi
                                        lehetőségeken</u></li>
                                    <li>A személyes adatok tárolása papír alapon és elektronikus formában történik
                                        úgy, hogy az érintettek azonosítását csak a szükséges ideig tegye lehetővé.
                                        A személyes adatok ennél hosszabb ideig történő tárolására csak akkor
                                        kerülhet sor, ha a tárolás közérdekű archiválás céljából, tudományos és
                                        történelmi kutatási célból vagy statisztikai célból történik.
                                    </li>
                                    <li>A személyes adatok kezelése és megfelelő biztonsága technikai és szervezési
                                        intézkedések alkalmazásával biztosítva van. Az adatok jogosulatlan vagy
                                        jogellenes kezelése, véletlen elvesztése, megsemmisítése vagy károsodása
                                        elleni védelem biztosított.
                                    </li>
                                    <li>Az adatvédelem elvei minden azonosított vagy azonosítható természetes
                                        személyre vonatkozó információ esetében alkalmazásra kerülnek.
                                    </li>
                                </ul>
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Kezelt adatok köre</th>
                                        <th>Az adatkezelés célja</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Név</td>
                                        <td>Azonosítás, kapcsolattartás, nyilvántartás.</td>
                                    </tr>
                                    <tr>
                                        <td>Lakcím</td>
                                        <td>Azonosítás, kapcsolattartás, nyilvántartás.</td>
                                    </tr>
                                    <tr>
                                        <td>Telefonszám</td>
                                        <td>Kapcsolattartási célból szükséges.</td>
                                    </tr>
                                    <tr>
                                        <td>E-mail</td>
                                        <td>Kapcsolattartási célból szükséges.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p><u>Az adatkezelési tájékoztatóban nem szereplő kérdések tekintetében az adatkezelési
                                    szabályzat, illetve annak alapjául szolgáló törvények és rendeletek az irányadók.
                                </u></p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PrivacyPolicy;