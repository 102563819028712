import React, {Component} from 'react';
import Carousel from "react-bootstrap/Carousel";
import {publicFetch} from "../../utils/fetch";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import ReactGA from "react-ga4";
import {useTranslation} from "react-i18next";

const ImagePopupModal = (props) => {
    const {t} = useTranslation();
    const hasImage = () => {
        return props.image !== null;
    }
    return (
        <>
            {hasImage() && <Modal
                size="xl"
                show={hasImage()}
                onHide={() => props.onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("expand_image")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="position-relative">
                    <Button className="image-popup-modal-button-left btn-primary" onClick={props.onPrevious}>&lt;</Button>
                    <Button className="image-popup-modal-button-right btn-primary" onClick={props.onNext}>&gt;</Button>
                    <img
                        className="d-block w-100"
                        src={props.image}
                        alt=""/>

                </Modal.Body>
            </Modal>}
        </>
    );
}

class PropertyImagesCorosel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.property_id,
            isLoading: true,
            isError: false,
            showImage: null,
            items: []
        }
        this.onImageClick = this.onImageClick.bind(this);
        this.onImageHide = this.onImageHide.bind(this);
        this.onPrev = this.onPrev.bind(this);
        this.onNext= this.onNext.bind(this);
    }

    onImageClick(image, imageid) {
        let newState = {
            id: this.state.id,
            isLoading: this.state.isLoading,
            isError: this.state.isError,
            showImage: this.state.showImage,
            items: this.state.items
        };
        newState.showImage = image;
        newState.current_imageid = imageid;
	ReactGA.event("property_image_action", {"property_id": this.state.id, "image_id": newState.current_imageid, "action": "open"});
        this.setState(newState);
    }
    onPrev() {
        let newState = {
            id: this.state.id,
            isLoading: this.state.isLoading,
            isError: this.state.isError,
            items: this.state.items
        };
        console.log(this.state.items, this.state.current_imageid);
        newState.current_imageid=(this.state.current_imageid-1+this.state.items.length)%this.state.items.length;
        newState.showImage = this.state.items[newState.current_imageid].src;
	ReactGA.event("property_image_action", {"property_id": this.state.id, "image_id": newState.current_imageid, "action": "prev"});
        this.setState(newState);
    }
    onNext() {
        let newState = {
            id: this.state.id,
            isLoading: this.state.isLoading,
            isError: this.state.isError,
            items: this.state.items
        };
        newState.current_imageid=(this.state.current_imageid+1)%this.state.items.length;
        newState.showImage = this.state.items[newState.current_imageid].src;
	ReactGA.event("property_image_action", {"property_id": this.state.id, "image_id": newState.current_imageid, "action": "next"});
        this.setState(newState);
    }

    onImageHide() {
        let newState = {
            id: this.state.id,
            isLoading: this.state.isLoading,
            isError: this.state.isError,
            showImage: this.state.showImage,
            items: this.state.items
        };
        newState.showImage = null;
	ReactGA.event("property_image_action", {"property_id": this.state.id, "action": "close"});
        this.setState(newState);
    }

    componentDidMount() {
        this.loadFromServer();
    }

    loadFromServer = async () => {
        publicFetch
            .get('/property/' + this.state.id + "/images", {})
            .then(response => response.data)
            .then(data => {
                this.setState({
                    id: this.state.id,
                    items: data,
                    isLoading: false,
                    isError: false,
                    showImage: null
                });
            })
            .catch(error => {
                this.setState({
                    id: this.state.id,
                    items: [],
                    isLoading: false,
                    isError: true,
                    error: error.message,
                    showImage: null
                });
            })
    };

    render() {
        return (
            <>
                <ImagePopupModal image={this.state.showImage} onHide={this.onImageHide}
                                 onPrevious={this.onPrev} onNext={this.onNext}/>
                {this.state.isLoading ? (
                    <>
                        Loading....
                    </>
                ) : (
                    <>
                        {this.state.isError ? (
                            <>
                                Hiba: Nem sikerült betölteni a képeket
                            </>
                        ) : (
                            <Carousel className="property-images bg-secondary" interval={3000}>
                                {this.state.items.map((item, key) =>
                                    (
                                        <Carousel.Item key={key}>
                                            <img
                                                className="d-block"
                                                src={item.src}
                                                alt={item.alt}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.onImageClick(item.src, key);
                                                }}
                                            />

                                            <Carousel.Caption>
                                                {item.title && <h3>{item.title}</h3>}
                                                {item.text && <p>{item.text}</p>}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    )
                                )}
                            </Carousel>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default PropertyImagesCorosel;
