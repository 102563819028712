import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

function toReviewItem(review, id, t) {

    return (<div className="review_block row mb-3" key={id}>
        <div className="col-12 col-lg-2"><img src={review.profile_photo_url} alt="who" referrerPolicy="no-referrer"/>
        </div>
        <div className="col-12 col-lg-10">
            {t("author",{ ns: 'about_us' })}: <a href="review.author_url">{review.author_name}</a><br/>
            {t("date",{ ns: 'about_us' })}: {review.relative_time_description}<br/>
            {t("rating",{ ns: 'about_us' })}: {review.rating}<br/>
            {review.text}
        </div>
    </div>);
}


const GoogleReviews = (props) => {
    const [data, setData] = useState("");
    const divId = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://maps.googleapis.com/maps/api/js?key=" + props.apiKey + "&libraries=places";
        document.body.appendChild(scriptTag);

        scriptTag.onload = () => {
            var placesService = new window.google.maps.places.PlacesService(divId.current);
            placesService.getDetails(
                {placeId: props.locationId},
                function (place, status) {
                    var newData = [];
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        for (var i = 0; i < place.reviews.length; ++i) {
                            newData.push(toReviewItem(place.reviews[i], "review_" + i, t));
                        }
                        setData(newData);
                    }
                });
        };
    }, [props.apiKey, props.locationId, t])

    return (<>
        <div className="container-fluid" ref={divId}>{data}</div>
    </>);
}

export default GoogleReviews;