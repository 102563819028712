import React, {useContext} from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import {BootstrapContext} from "../context/BootstrapContext";
import {useTranslation} from "react-i18next";

const TextDisplayRow = (props) => {
    const label = props.label;
    const hasValue = props.value != null;

    return (
        <>
            {hasValue &&
            <Row className={props.className}    >
                <Col>
                    {label}:
                </Col>
                <Col>{props.value} {props.unit}</Col>
            </Row>
            }
        </>
    );
}


TextDisplayRow.propTypes = {
    emptyText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    label: PropTypes.string.isRequired,
    unit: PropTypes.string,
    className: PropTypes.string
};
TextDisplayRow.defaultProps = {};

const SelectorDisplayRow = (props) => {
    const { t } = useTranslation();
    const label = props.label;
    const hasValue = props.id != null;
    const property_type = props.property_type;

    const displayValue = props.source
        .filter(item => parseInt(item.id) === parseInt(props.id))
        .map(item => item.name);
    return (
        <>
            {hasValue &&
            <Row className={props.className}>
                <Col>
                    {label}:
                </Col>
                <Col>{t(property_type+"."+displayValue,{ ns: 'property_fields' })}</Col>
            </Row>
            }
        </>
    );
}


SelectorDisplayRow.propTypes = {
    id: PropTypes.number,
    emptyText: PropTypes.string,
    label: PropTypes.string.isRequired,
    source: PropTypes.array.isRequired,
    property_type: PropTypes.string.isRequired,
    className: PropTypes.string
};
SelectorDisplayRow.defaultProps = {};

const PropertyDetailsPanel = (props) => {
    const { t } = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);
    const property = props.item;

    function getNumberOfBathrooms(premises) {
        return premises
            .map(r => getBootstrapData("premise_type").find(pt => pt.id === r.premise_type_id))
            .filter(p => p.count_as_bathroom).length;
    }

    return (
        <Card className="bg-secondary ml-3 ml-md-0 mt-3 mt-md-0">
            <Card.Header>
                <Card.Title>{t('property_parameters',{ ns: 'property_fields' })}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Container>
                        <SelectorDisplayRow label={t("building_type_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("property_type")}
                                            property_type="property_type"
                                            id={property.property_type_id}
                                            className="gradline"/>
                        <SelectorDisplayRow label={t("building_state_in_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("building_state")}
                                            property_type="building_state"
                                            id={property.property_state_inner_id}/>
                        <SelectorDisplayRow label={t("building_state_out_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("building_state")}
                                            property_type="building_state"
                                            id={property.property_state_outer_id}
                                            className="gradline"/>
                        <TextDisplayRow label={t("living_size",{ ns: 'property_fields' })}
                                        value={property.living_size}
                                        unit="m2"/>
                        <SelectorDisplayRow label={t("saniter_type_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("saniter_type")}
                                            property_type="saniter_type"
                                            id={property.saniter_type_id}
                                            className="gradline"/>
                        <TextDisplayRow label={t("number_of_bathroom_label",{ ns: 'property_fields' })}
                                        value={getNumberOfBathrooms(property.premises)}
                                        unit="db"/>
                        <SelectorDisplayRow label={t("heating_type_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("heating_type")}
                                            property_type="heating_type"
                                            id={property.heating_type_id}
                                            className="gradline"/>
                        <SelectorDisplayRow label={t("parking_type_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("parking_type")}
                                            property_type="parking_type"
                                            id={property.plot.parking_id}/>
                        <TextDisplayRow label={t("year_of_build_label",{ ns: 'property_fields' })}
                                        value={property.building.year_of_build}
                                        className="gradline"/>
                        <TextDisplayRow label={t("year_of_renovation_label",{ ns: 'property_fields' })}
                                        value={property.building.year_of_renovation}/>
                        <SelectorDisplayRow label={t("orientation_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("orientation")}
                                            property_type="orientation"
                                            id={property.orientation_id}
                                            className="gradline"/>
                        <SelectorDisplayRow label={t("sight_label",{ ns: 'property_fields' })}
                                            source={getBootstrapData("sight")}
                                            property_type="sight"
                                            id={property.sight_id}/>
                    </Container>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default PropertyDetailsPanel;