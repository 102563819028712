import Form from "react-bootstrap/Form";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {BootstrapContext} from "../context/BootstrapContext";
import InputGroup from "react-bootstrap/InputGroup";
import {Multiselect} from "multiselect-react-dropdown";
import {useTranslation} from "react-i18next";

const PlotCategorySelect = (props) => {
    const { t } = useTranslation();
    const {getBootstrapData} = useContext(BootstrapContext);
    const isMultiline = !props.isSingleSelect;
    const [selected, setSelected] = useState(null);
    const onMultiSelectChange = (selectedList, __) => {
        setSelected(selectedList);
        props.onChange(selectedList);
    }
    const onSingleSelectChange = (e) => {
        props.onChange(e.target.value);
    }

    useEffect(() => {
        if (isMultiline) {
            if (selected === null) {
                // if (Array.isArray(props.defaultValue)) {
                //     setSelected([...props.defaultValue]);
                // } else {
                //     setSelected([props.defaultValue]);
                // }
            }
        }
    }, [isMultiline, selected, props.defaultValue]);


    return (
        <Form.Group controlId={props.controlId}>
            <InputGroup>
            <Form.Label>Övezeti besorolás</Form.Label>
            {isMultiline &&
            <Multiselect
                classNane="bg-secondary"
                options={getBootstrapData('plot_category')} // Options to display in the dropdown
                selectedValues={selected} // Preselected value to persist in dropdown
                onSelect={onMultiSelectChange} // Function will trigger on select event
                onRemove={onMultiSelectChange} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
            />}
            {!isMultiline &&
            <Form.Control as="select"
                          onChange={onSingleSelectChange}
                          defaultValue={parseInt(props.defaultValue)}>
                {!props.defaultValue && <option key="0" value="">{t(props.emptyText)}</option>}
                {getBootstrapData('plot_category').map((item, i) => {
                        let selected = parseInt(item.id) === parseInt(props.defaultValue);
                        return <option key={i} value={parseInt(item.id)}
                                       selected={selected}>{t('plot_category.'+item.name,{'ns':'property_fields'})}</option>;
                    }
                )}
            </Form.Control>}
            </InputGroup>
        </Form.Group>
    );
}

PlotCategorySelect.propTypes = {
    controlId: PropTypes.string,
    isSingleSelect: PropTypes.bool,
    onChange: PropTypes.func,
    emptyText: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
};
PlotCategorySelect.defaultProps = {
    controlId: "formPlotCategory",
    isSingleSelect: false,
    onChange: (e) => {
    },
    emptyText: "Nincs kiválasztva",
    defaultValue: null
};

export default PlotCategorySelect;
