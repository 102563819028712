import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import {LoadingContext} from "../components/context/LoadingContext";
import GoogleReviews from "../components/GoogleReviews";
import {Trans, useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Reviews = () => {
    const { t } = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(() => {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("reviews.title", {ns:"seo"})}</title>
                <meta
                    name="keywords"
                    content={t("reviews.keywords", {ns:"seo"})}
                />
                <meta
                    name="description"
                    content={t("reviews.description", {ns:"seo"})}
                />
            </Helmet>
            <Navigation/>
            <div className="bg-image3 w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
            <Container>
                <Row className="m-3">
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <Card className="bg-secondary">
                            <Card.Header className="font-weight-bolder text-center">{t('reviews2',{ ns: 'about_us' })}
                                <br/>
                                <Trans i18nKey="reviews3" ns="about_us">.<a href="https://www.google.com/maps/place/Magic+House+ingatlaniroda/@47.4018775,18.8993467,17z/data=!4m8!3m7!1s0x4741e10f85c88d5f:0x1e7b8ad4e89e2583!8m2!3d47.4018775!4d18.8993467!9m1!1b1!16s%2Fg%2F11mr2_j53z?entry=ttu">Google értékelések</a>.</Trans>

                            </Card.Header>
                                <Card.Body>
                                    <p className="m-2 font-weight-bold text-center text-info"><Trans i18nKey="reviews4" ns="about_us">Megköszönjük, ha Ön is megosztja tapasztalatait! Megteheti:
                                    <a href="https://reviewthis.biz/magichouse">itt</a>.</Trans></p>
                            <GoogleReviews
                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        locationId="ChIJX43IhQ_hQUcRgyWe6NSKex4"/>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
);
}

export default Reviews;