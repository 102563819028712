import React, {useContext, useEffect, Component} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import {LoadingContext} from "../components/context/LoadingContext";
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react-17';
import MHiroda from "../images/MHiroda.jpg";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import MZ from "../images/MagicZoli.JPG";
import MD from "../images/MagicDetti.jpg";

class MapContainer extends Component {
    render() {
        return (
            <Map containerStyle={{position: "relative", height: "50vh"}}
                 google={this.props.google}
                 zoom={19}
                 initialCenter={{
                     lat: 47.4018787,
                     lng: 18.8991331
                 }}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Marker
                    name={'Magichouse ingatlaniroda'}
                    position={{lat: 47.4018787, lng: 18.8991331}}
                    icon={{
                        url: "/MH_logo.svg",
                        anchor: new this.props.google.maps.Point(32, 32),
                        scaledSize: new this.props.google.maps.Size(64, 64)
                    }}/>
            </Map>
        );
    }
}

const Terkep = GoogleApiWrapper((props) => ({
    apiKey: props.apiKey
}))(MapContainer)

const ContactUs = () => {
    const {t} = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(() => {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("contact_page.title", {ns:"seo"})}</title>
                <meta
                    name="keywords"
                    content={t("contact_page.keywords", {ns:"seo"})}
                />
                <meta
                    name="description"
                    content={t("contact_page.description", {ns:"seo"})}
                />
            </Helmet>
        <Navigation/>
        <div className="kapcsolat-bg w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
        <Container>
            <Row className="m-3">
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h4 className="text-center">{t('contact')}</h4>
                        </Card.Header>
                        <Card.Body>
                            <Container className="flex-container flex-wrap">
                                <div className="flex-item-left order-1 order-lg-0 w-50">
                                    <h5>{t('opening_hours1',{ ns: 'about_us' })}:</h5>
                                    <p>{t('opening_hours2',{ ns: 'about_us' })}<br/>{t('opening_hours3',{ ns: 'about_us' })}</p>
                                    <p><i className="fas fa-parking"/><b>{t('parking',{ ns: 'about_us' })}</b></p>
                                <h5>{t('call',{ ns: 'about_us' })}</h5>
                                    <div className="p-3">
                                        <img className="w-25" src={MZ} alt={t("Balogh Zoltán érdi ingatlanközvetítő",{ ns: 'about_us' })}/>
                                        <p className="font-weight-bold">Balogh Zoltán</p>
                                        <p>+36 70 600 2393</p>
                                        <a className="text-info" href={"mailto:"}>balogh.zoltan@magichouse.hu</a>
                                    </div>
                                    <div className="p-3">
                                        <img className="w-25" src={MD} alt={t("Balogh-Bor Bernadett érdi ingatlanügynök",{ ns: 'about_us' })}/>
                                        <p className="font-weight-bold">Balogh-Bor Bernadett</p>
                                        <p>+36 70 466 3892</p>
                                        <a className="text-info" href={"mailto:"}>bor.bernadett@magichouse.hu</a>
                                    </div>
                                <h5><br/>{t('adress',{ ns: 'about_us' })}</h5>
                                <p>2030 Érd Bajcsy-Zsilinszky út 61.<br/></p>
                                    <p><i className="fas fa-building"></i><b>{t('adress2',{ ns: 'about_us' })}</b></p>
                            </div>
                            <div className="flex-item-right order-0 order-lg-1 w-50">
                                <img className="w-100" src={MHiroda} alt="Érd Ingatlaniroda"/>
                            </div>
                            <div className="border border-warning flex-fill order-2 mt-5">
                                <Terkep apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}/>
                            </div>
        </Container>
        </Card.Body>
</Card>
</Col>
</Row>
</Container>
</>
)

}

export default ContactUs;