import React from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactGA from "react-ga4";
import {useTranslation} from "react-i18next";

const ContactCard = (props) => {
    const { t } = useTranslation();
    const contact = props.item;

    const onMouseOver = (e) => {
        e.preventDefault();
        ReactGA.event("property_interact", {"property_id": props.item.id, "action": "contact_card"});
    }

    return (
        <Card className={"bg-secondary ml-3 ml-md-0 " + props.className} onMouseOver={onMouseOver}>
            <Card.Header className="gradient2">
                <Card.Title className="font-bolder text-white">{t('agent')} {contact.name}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1">
                <Card.Text as="div">
                    <Container>
                        <Row>
                            <Col md="5">
                                {contact.src && <img className="w-100" alt={contact.name} src={contact.src}/>}
                            </Col>
                            <Col md="7" className="p-0">
                                <Container>
                                    <Row>
                                        <Col md="3">Tel:</Col>
                                        <Col md="9"
                                             className="text-info text-nowrap contactCardText">{contact.phone}</Col>
                                    </Row>
                                    <Row>
                                        <Col md="3"> Email:</Col>
                                        <Col md="9" className="text-info text-nowrap contactCardText"><a
                                            className="text-info text-nowrap contactCardText"
                                            href={"mailto:" + contact.email}>{contact.email}
                                        </a></Col>
                                    </Row>
                                </Container>
                            </Col>

                        </Row>
                    </Container>
                </Card.Text>
            </Card.Body>
        </Card>
    )
        ;
}

export default ContactCard;