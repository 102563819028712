import Form from "react-bootstrap/Form";
import React from "react";
import {Col} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";

const PropertyRangeSelector = (props) => {
    const numberFilter = (evt) => {
        if (evt.which < 48 || evt.which > 57) {
            if (evt.which !== 44 && evt.which !== 46)
                evt.preventDefault();
        }
        // if (evt.target.name.endsWith("min")) {
        //     props.onMinChange(evt.target.value + evt.key);
        // } else {
        //     props.onMaxChange(evt.target.value + evt.key);
        // }
    }
    const deleteFilter = (evt) => {
        // if (evt.key === "Delete" || evt.key === "Backspace") {
            if (evt.target.name.endsWith("min")) {
                props.onMinChange(evt.target.value);
            } else {
                props.onMaxChange(evt.target.value);
            }
        // }
    }
    return (
        <Container fluid="md">
            <Row>
                <Form.Group>
                    <Form.Label>{props.label}</Form.Label>
                    <Row>
                        <Col md="6" xs="6">
                            <Form.Control inline="true"
                                          autoComplete="off"
                                          name={props.controlId + "_min"}
                                          type={props.type}
                                          defaultValue={props.defaultValueMin}
                                          size="sm"
                                          onKeyPress={numberFilter}
                                          onKeyUp={deleteFilter}
                                          placeholder={props.unit}/>
                        </Col>
                        <Col md="6" xs="6">
                            <Form.Control inline="true"
                                          autoComplete="off"
                                          name={props.controlId + "_max"}
                                          type={props.type}
                                          defaultValue={props.defaultValueMax}
                                          size="sm"
                                          onKeyPress={numberFilter}
                                          onKeyUp={deleteFilter}
                                          placeholder={props.unit}/>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Container>
    );
}

PropertyRangeSelector.propTypes = {
    controlId: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onMinChange: PropTypes.func,
    onMaxChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    defaultValueMin: PropTypes.any,
    defaultValueMax: PropTypes.any
};
PropertyRangeSelector.defaultProps = {
    controlId: "formRange",
    type: "text",
    onChange: (e) => {
    },
    onMinChange: (e) => {
        console.warn("Event ignored");
    },
    onMaxChange: (e) => {
        console.warn("Event ignored");
    },
    defaultValueMin: null,
    defaultValueMax: null
};

export default PropertyRangeSelector;