import React from 'react';
import * as ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/theme_final.css"
//import "./css/theme_1600079622191.css"
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./fonts/TradeMarkDemo.otf";
import ReactGA from "react-ga4";

ReactGA.initialize("G-PP27FM125Y");

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
