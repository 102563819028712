import React, {useContext} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import PropertyCitySelect from "../components/selectors/PropertyCitySelect";
import PropertyPurposeSelect from "../components/selectors/PropertyPurposeSelect";
import Col from "react-bootstrap/Col";
import PropertyRangeSelector from "../components/selectors/PropertyRangeSelector";
import PropertyTypeSelect from "../components/selectors/PropertyTypeSelect";
import BuildingTypeSelect from "../components/selectors/BuildingTypeSelect";
import HeatingTypeSelect from "../components/selectors/HeatingTypeSelect";
import ParkingTypeSelect from "../components/selectors/ParkingTypeSelect";
import SaniterTypeSelect from "../components/selectors/SaniterTypeSelect";
import StoreyTypeSelect from "../components/selectors/StoreyTypeSelect";
import PlotCategorySelect from "../components/selectors/PlotCategorySelect";
import BuildingStateSelect from "../components/selectors/BuildingStateSelect";
import {BootstrapContext} from "../components/context/BootstrapContext";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


const AdvancedSearch = () => {
    const {getBootstrapData} = useContext(BootstrapContext);
    return (
        <>
            <Navigation/>
            <Container fluid="md" className="advancedsearch">
                <Row className="m-3">
                    <Col md={12}>
                        <Form action="/search" method="get">
                            <Card className="bg-gradient-primary">
                                <Card.Header className="bg-gradient-success text-white">
                                    Részletes keresés
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col md="3"><PropertyPurposeSelect controlId="purpose"/></Col>
                                            <Col md="3"><PropertyCitySelect controlId="city"/></Col>
                                            <Col md="3"><PropertyTypeSelect controlId="type"/></Col>
                                        </Row>
                                        <Row>
                                            <Col md="3"><PropertyRangeSelector controlId="price" unit="m Ft"
                                                                               label="Ár"/></Col>
                                            <Col md="3"><PropertyRangeSelector controlId="size" unit="m2"
                                                                               label="Lakóterület"/></Col>
                                            <Col md="3"><PropertyRangeSelector controlId="room_count" unit="db"
                                                                               label="Szobák száma"/></Col>
                                        </Row>
                                        <Row md="12">
                                            <Col md="3"> <BuildingTypeSelect controlId="building_type"/></Col>
                                            <Col md="3"> <BuildingStateSelect label="Állapot"/></Col>
                                            <Col md="3"><HeatingTypeSelect controlId="heating_type"/></Col>
                                            <Col md="3"><ParkingTypeSelect controlId="parking_type"/></Col>
                                        </Row>
                                        <Row>
                                            <Col md="3"><StoreyTypeSelect controlId="storey_type"/></Col>
                                            <Col md="3"><PropertyRangeSelector controlId="batroom_count"
                                                                               label="Fürdőszobák száma"
                                                                               unit="db"/></Col>
                                            <Col md="3"><SaniterTypeSelect controlId="saniter_type"/></Col>

                                        </Row>
                                        <Row>
                                            <Col md="3"><PropertyRangeSelector controlId="plot_size" unit="m2"
                                                                               label="Telekméret"/></Col>
                                            <Col md="3"><PlotCategorySelect controlId="plot_category"/></Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                {getBootstrapData('extras').filter(i => i.show_on_advanced === true).map((item, i) => {
                                                        let uid = "check_" + i;
                                                        return (
                                                            <div key={i}
                                                                 className="form-check form-check-inline extrastyle">
                                                                <input type="checkbox" className="form-check-input"
                                                                       id={uid}/>
                                                                <label className="form-check-label"
                                                                       htmlFor={uid}>{item.name}</label>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                                <Card.Footer className="bg-success">
                                    <Button className="float-right" variant="outline-light"
                                            type="submit">Keress!</Button>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdvancedSearch;