import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navigation from "../containers/Navigation";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import PropertyTypeSelect from "../components/selectors/PropertyTypeSelect";
import PropertyCitySelect from "../components/selectors/PropertyCitySelect";
import PropertyRangeSelector from "../components/selectors/PropertyRangeSelector";
import Button from "react-bootstrap/Button";
import Suggestions from "../containers/Suggestions";
import i01 from "../images/furdo03.jpg";
import i08 from "../images/halo02.jpg";
import i09 from "../images/halo03.jpg";
import i13 from "../images/haz02.jpg";
import i24 from "../images/haz12.jpg";
import i26 from "../images/haz14.jpg";
import i31 from "../images/konyha02.jpg";
import i32 from "../images/konyha03.jpg";
import i36 from "../images/nappali03.jpg";
import i37 from "../images/nappali04.jpg";
import i39 from "../images/nappali06.jpg";
import i40 from "../images/nappali07.jpg";
import i43 from "../images/nappali10.jpg";
import i45 from "../images/nappali12.jpg";
import i46 from "../images/nappali13.jpg";
import i51 from "../images/haz15.jpg";
import i52 from "../images/haz16.jpg";
import i53 from "../images/haz17.jpg";
import {LoadingContext} from "../components/context/LoadingContext";
import { Helmet } from "react-helmet";


function HomeSearchSmallScreen(props) {
    const { t } = useTranslation();
    const propertyTypeHiddenInput = React.createRef();
    const cityHiddenInput = React.createRef();

    const onPropertyTypeChange = (value) => {
        propertyTypeHiddenInput.current.value = value;
    }
    const onCityChange = (value) => {
        cityHiddenInput.current.value = value;
    }
    return <Container fluid="true" className="d-block d-md-none w-100 p-2">
        <Form action="/search" method="get">
            <input type="hidden" name="property_type_id" ref={propertyTypeHiddenInput}/>
            <input type="hidden" name="city_id" ref={cityHiddenInput}/>
            <Row className="my-navbar-bg text-white">
                <Col xs="12">
                    <PropertyTypeSelect isSingleSelect={true}
                                        controlId="type"
                                        onChange={onPropertyTypeChange}/>
                </Col>
                <Col xs="12">
                    <PropertyCitySelect isSingleSelect={true}
                                        controlId="city"
                                        onChange={onCityChange}/>
                </Col>
                <Col xs="6">
                    <PropertyRangeSelector unit="m Ft"
                                           controlId="price"
                                           label={t('price')}/>
                </Col>
                <Col xs="6">
                    <PropertyRangeSelector unit="m2"
                                           controlId="size"
                                           label={t('size')}/>
                </Col>
                <Col xs="6">
                    <PropertyRangeSelector unit="db"
                                           controlId="room_count"
                                           label={t('room_count')}/>
                </Col>
                <Col sm="12">
                    <div>&nbsp;</div>
                    <Button onClick={props.onClick} variant="outline-light" type="submit">
                        {t('search')}
                    </Button>
                    <Button onClick={props.onClick} variant="outline-light" type="submit" href="/search?">
                        {t('all_properties')}
                    </Button>
                </Col>
                <Col className="d-md-flex justify-content-end">
                    {/*<a href="/advanced" className="text-warning">*/}
                    {/*    Részletes keresés*/}
                    {/*</a>*/}
                </Col>
            </Row>
        </Form>
    </Container>;
}

function HomeSearchLargeSreen(props) {
    const { t } = useTranslation();
    const propertyTypeHiddenInput = React.createRef();
    const cityHiddenInput = React.createRef();

    const onPropertyTypeChange = (value) => {
        propertyTypeHiddenInput.current.value = value;
    }
    const onCityChange = (value) => {
        cityHiddenInput.current.value = value;
    }
    return <Container
        className="d-none position-absolute d-md-block w-100 align-content-center"
        style={{
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)'
        }}>
        <Form action="/search" method="get">
            <input type="hidden" name="property_type_id" ref={propertyTypeHiddenInput}/>
            <input type="hidden" name="city_id" ref={cityHiddenInput}/>
            <Row className="my-navbar-bg text-white">
                <Col md="2">
                    <PropertyTypeSelect controlId="type" isSingleSelect={true} onChange={onPropertyTypeChange}/>
                </Col>
                <Col md="2">
                    <PropertyCitySelect controlId="city" isSingleSelect={true} onChange={onCityChange}/>
                </Col>
                <Col md="2">
                    <PropertyRangeSelector
                        controlId="price"
                        unit="m Ft"
                        label={t('price')} />
                </Col>
                <Col md="2">
                    <PropertyRangeSelector
                        controlId="size"
                        unit="m2"
                        label={t('size')} />
                </Col>
                <Col md="2">
                    <PropertyRangeSelector
                        controlId="room_count"
                        unit="db"
                        label={t('room_count')} />
                </Col>
                <Col md="2">
                    <div>&nbsp;</div>
                    <Button onClick={props.onClick} variant="outline-light" type="submit">
                        {t('search')}
                    </Button>
                    <Button onClick={props.onClick} variant="outline-light" type="submit" href="/search?">
                        {t('all_properties')}
                    </Button>
                </Col>
            </Row>
        </Form>
    </Container>;
}

const Home = () => {
    const {t} = useTranslation();
    const {completeLoad, requestLoadScreen} = useContext(LoadingContext);
    const images = [i40, i31, i13, i09, i43, i51, i01, i32, i36, i37, i52, i46, i45, i26, i53, i08, i24, i39];
    const onSearchClick = (e) => {
        requestLoadScreen();
    };

    useEffect(() => {
        setTimeout(() => {
            completeLoad();
        }, 1000);
    });
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t("home_page.title", {ns:"seo"})}</title>
            <meta
                name="keywords"
                content={t("home_page.keywords", {ns:"seo"})}
            />
            <meta
                name="description"
                content={t("home_page.description", {ns:"seo"})}
            />
        </Helmet>
            <Navigation/>
            <Carousel
                className="d-none d-md-block home-carousel w-100 align-items-center position-relative justify-content-center"
                indicators={false} controls={false} interval={3000} pause={false}>
                {images.map((image, i) =>
                    <Carousel.Item key={i}>
                        <div className="justify-content-center">
                            <img className="d-flex min-vw-100"
                                 src={image}
                                 alt=""
                                 style={{
                                     "maxHeight": "800px"
                                 }}
                            />
                        </div>
                    </Carousel.Item>
                )}
            </Carousel>
            <div className="position-relative">
                <HomeSearchLargeSreen onClick={onSearchClick}/>
            </div>
            <HomeSearchSmallScreen onClick={onSearchClick}/>
            <Suggestions/>
        </>
    );
}

export default Home;