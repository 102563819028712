import React from 'react';
import Card from "react-bootstrap/Card";
import {getIdText} from "../../utils/property_tools";
import {useTranslation} from "react-i18next";

const DetailedDescriptionPanel = (props) => {
    const { t } = useTranslation();
    const item = props.item;
    return (
        <Card className="ml-3 bg-secondary">
            <Card.Header>
                <Card.Title>{t('description')}#{getIdText(item.id)}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <div dangerouslySetInnerHTML={{__html:item.description}} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
}


export default DetailedDescriptionPanel;